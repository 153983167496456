#perfil {
	margin-bottom: 40px;

	& .mt30 {
		margin-top: 30px;
	}

	& .img-perfil {
		border-radius: 50%;
		min-width: 30%;
	}

	// & .botonRojo {
	// 	width: max-content;
	// 	margin-top: 5px;
	// 	margin-bottom: 50px;
	// 	border: 1px solid coral;
	// 	background-color: coral;
	// 	border-radius: 6px;
	// 	transition: all 0.5s;
	// 	color: #fff;
	// 	cursor: pointer;
	// }

	// & .MuiInputBase-input {
	// 	color: #525252 !important;
	// 	font-size: 22px;
	// }

	// & .MuiFormLabel-root {
	// 	color: #525252 !important;
	// }

	// & .MuiInputLabel-outlined.MuiInputLabel-shrink {
	// 	transform: translate(0px, -18px) scale(1) !important;
	// 	color: #525252 !important;
	// }

	// & .MuiInputBase-root {
	// 	margin-bottom: 15px;
	// 	border-radius: 5px;
	// 	background-color: #fff;
	// 	margin-bottom: 30px;
	// }

	// & .MuiFilledInput-underline {
	// 	&::before,
	// 	&::after {
	// 		border: none !important;
	// 	}
	// }

	// & .MuiFormLabel-root.Mui-focused {
	// 	color: #525252 !important;
	// 	font-weight: normal;
	// 	font-size: 14px;
	// }

	// & .MuiTypography-body1 {
	// }

	// & label.MuiFormControlLabel-root {
	// 	color: #525252 !important;
	// }

	&.password-check {
		& div {
			display: flex;
			padding: 10px;
			align-items: center;
			font-weight: 500;
			color: red;
		}
	}

	& .password-check {
		& img {
			height: 25px;
			padding-right: 10px;
		}
	}
}

@media only screen and (max-width: 600px) {
	#perfil {
		& .img-perfil {
			width: 50%;
		}
	}
}

@media only screen and (min-width: 600px) {
	#perfil {
		& .img-perfil {
			width: 100%;
		}
	}
}

@media only screen and (min-width: 768px) {
	#perfil {
		& .img-perfil {
			width: 100%;
		}
	}
}

@media only screen and (min-width: 992px) {
	#perfil {
		& .img-perfil {
			width: 50%;
		}
	}
}

@media only screen and (min-width: 1200px) {
}
