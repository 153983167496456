.sidebar {
	position: fixed;
	left: 0;
	top: 0;
	width: 260px;
	height: 100%;
	background-color: var(--secondary-color);
	padding: 20px 14px;
	box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
	display: flex;
	flex-direction: column;
	z-index: 999;
	transition: var(--default-transition);
	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-track {
		background-color: var(--scroll-track-bg-color);
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 100vh !important;
		background-color: var(--scroll-thumb-bg-color);
		outline: 1px solid rgba(0, 0, 0, 0.02);
		outline-offset: -1px;
	}

	// toggling this class helps to hide/unhide the sidebar
	&.sidebar-show {
		@media (max-width: 1024px) {
			transform: translateX(0);
		}
	}

	// showing icons only on sidebar
	@media (max-width: 1200px) {
		width: auto;
		padding: 20px 6px;
	}

	// hiding full on small screen
	@media (max-width: 1024px) {
		width: 260px;
		transform: translateX(-100%);
	}

	.sidebar-top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 28px;
		padding-bottom: 4px;
		border-bottom: 1px solid var(--border-color-inverted);
	}

	.sidebar-brand {
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 6px;
	}

	.sidebar-brand-text {
		text-transform: uppercase;
		font-weight: 900;
		color: var(--logo-color);
		margin-bottom: 8px;

		@media (max-width: 1200px) {
			display: none;
		}

		@media (max-width: 768px) {
			display: inline-block;
		}
	}

	.sidebar-close-btn {
		background-color: var(--toggle-btn-bg-color);
		width: 28px;
		height: 28px;
		box-shadow: 0px 0px 4px rgba(71, 91, 232, 0.04);
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--toggle-btn-icon-color);
		border-radius: 2px;
		transition: var(--default-transition);
		display: none;

		@media (max-width: 1024px) {
			display: inline-flex;
		}

		&:hover {
			opacity: 0.8;
		}
	}

	.menu-item {
		margin-bottom: 8px;
		cursor: pointer;

		&:last-child {
			margin-bottom: 0;
		}

		@media (max-width: 1200px) {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.menu-link {
		width: 100%;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		column-gap: 11px;
		padding: 12px;
		border-radius: 12px;
		color: var(--side-text-color);
		transition: var(--default-transition);

		@media (max-width: 1200px) {
			padding: 2px;
			width: 100%;
			height: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
		}

		@media (max-width: 768px) {
			padding: 12px;
			border-bottom: 12px;
			width: 100%;
			height: 42px;
			justify-content: flex-start;
		}

		&:hover {
			background-color: var(--side-link-hover-color);
		}

		&.active {
			background-color: var(--primary-color);
			color: var(--light-color);
			box-shadow: 0px 0px 4px rgba(71, 91, 232, 0.04);
		}

		&.active-two {
			background-color: var(--lg-text-color);
			color: var(--background-color);
			box-shadow: 0px 0px 4px rgba(71, 91, 232, 0.04);
		}
	}

	.mennu-link-icon {
		display: flex;
		align-items: center;
	}

	.menu-link-text {
		font-family: var(--font-family-manrope);
		font-weight: 500;
		width: 100%;

		& .icon-menu {
			float: right;
		}

		@media (max-width: 1200px) {
			display: none;
		}
		@media (max-width: 1024px) {
			display: inline-block;
		}
	}

	.sidebar-body {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.sidebar-menu2 {
		margin-top: auto;
	}
}
