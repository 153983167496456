#reportedetalladoConsultor {
	& .recharts-yAxis .recharts-text {
		transform: translate(0, 0px);
		fill: var(--side-text-color) !important;
		font-size: 12px;
	}
	& .recharts-xAxis .recharts-text {
		transform: translate(0, 0px);
		fill: var(--side-text-color) !important;
		font-size: 12px;
	}
}
