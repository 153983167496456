#encuestaConsultorLista {
	& .badge {
		padding: 10px;
		width: 100px;
		height: 30px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		border-radius: 15px;
		margin-top: 10px;
	}

	& .amarillo {
		background-color: #e9bb00;
	}
	& .verde {
		background-color: #00b14a;
	}
	& .rojo {
		background-color: #c70039;
	}
	& svg {
		color: var(--side-text-color);
	}
}
