#login {
	background-image: url(../../../assets/images/login/bg1.png);
	// background-position: center;
	// background-repeat: no-repeat;
	// height: 100vh;
	// background-size: cover;
	// overflow: hidden;
	// display: flex;
	// justify-content: center;
	// align-items: center;
	height: 100vh;
	overflow: hidden;
	font-size: 18px;
	color: #5a5858;
	// background: transparent linear-gradient(180deg, #e10613 0%, #ad0e0e 100%) 0%  0% no-repeat padding-box;
	// background-image: url(../../resources/images/login/bg1.png);
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;

	// &::before {
	// 	content: '';
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// 	bottom: 0;
	// 	left: 0;
	// 	backdrop-filter: blur(25px);
	// }

	& .container-form-login {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: left !important;
		height: 100vh;
		flex-wrap: wrap;

		& form {
			overflow: hidden;
			backdrop-filter: blur(10px);
			border: 2px solid #5a5858;
			border-radius: 20px;
			padding: 20px;
			-webkit-box-shadow: -1px 0px 86px -16px rgba(0, 0, 0, 0.75);
			-moz-box-shadow: -1px 0px 86px -16px rgba(0, 0, 0, 0.75);
			box-shadow: -1px 0px 86px -16px rgba(0, 0, 0, 0.75);

			& .welcome {
				font-size: 25px;
				font-weight: bold;
			}

			& .subtitle {
				font-size: 18px;
				font-weight: normal;
			}

			& .reset-password {
				font-size: 14px;
				cursor: pointer;
				color: #5a5858;
				font-weight: bold;
			}

			// & .MuiInputBase-root {
			// color: red !important;
			// font-family: 'Montserrat Regular';
			// }

			.MuiInputBase-input {
				// font-family: 'Montserrat Regular';
				color: #5a5858 !important;
			}

			& .MuiInput-underline {
				&::after {
					border-bottom: 2px solid #5a5858 !important;
				}

				&::before {
					border-bottom: 2px solid #5a5858 !important;
				}
			}

			& .MuiFormLabel-root {
				// font-family: 'Montserrat Regular' !important;

				&.Mui-focused {
					color: #5a5858 !important;
				}
			}

			& .MuiInputLabel-outlined.MuiInputLabel-shrink {
				transform: translate(0px, -18px) scale(1) !important;
				// font-family: 'Montserrat Regular' !important;
				color: #5a5858 !important;
			}

			& .MuiOutlinedInput-input {
				// background: transparent linear-gradient(90deg, $color-azul 0%, $color-azul 24%, $color-azul 68%, $color-azul 100%) 0% 0% no-repeat padding-box !important;
				// background: $color-azul !important;
				border-radius: 10px !important;
				// font-family: 'Montserrat Regular' !important;
				// text-transform: uppercase;
			}

			& .MuiInputLabel-standard {
				font-size: 16px;
				// font-family: 'Montserrat Regular' !important;
			}

			& .MuiFormLabel-root {
				color: #5a5858 !important;
			}

			& .MuiInputBase-root {
				margin-bottom: 10px;
				border-radius: 20px !important;
			}

			& .MuiFilledInput-underline {
				&::before,
				&::after {
					border: none !important;
				}
			}

			& .MuiOutlinedInput-notchedOutline {
				border: #5a5858 !important;
			}

			& .MuiFormLabel-root.Mui-focused {
				font-weight: normal;
				font-size: 14px;
			}

			// & .MuiTypography-body1 {
			// 	font-family: 'Montserrat Regular' !important;
			// }

			// & label.MuiFormControlLabel-root {
			// 	font-family: 'Montserrat Regular';
			// }
		}
	}
}
