#ReporteEncuestaDeSatisfaccionOperativa {
	padding: 0 0 25px 0;
	& .recharts-yAxis .recharts-text,
	& .recharts-xAxis .recharts-text {
		transform: translate(0, 0px);
		fill: var(--side-text-color) !important;
	}
	& #servicios,
	& #ejecucion {
		& .recharts-text {
			fill: #fff !important;
			font-size: 19px;
		}
	}
}
