#satisfaccionesGenerales {
	padding: 0 0 15px 0;

	.MuiInputBase-input {
		// font-family: 'Montserrat Regular';
		color: var(--text-color-inverted) !important;
	}

	& .MuiInput-underline {
		&::after {
			border-bottom: 2px solid #5a5858 !important;
		}

		&::before {
			border-bottom: 2px solid #5a5858 !important;
		}
	}

	& .MuiFormLabel-root {
		// font-family: 'Montserrat Regular' !important;

		&.Mui-focused {
			color: var(--text-color-inverted) !important;
		}
	}

	& .MuiInputLabel-outlined.MuiInputLabel-shrink {
		transform: translate(0px, -18px) scale(1) !important;
		// font-family: 'Montserrat Regular' !important;
		color: var(--text-color-inverted) !important;
	}

	& .MuiOutlinedInput-input {
		// background: transparent linear-gradient(90deg, $color-azul 0%, $color-azul 24%, $color-azul 68%, $color-azul 100%) 0% 0% no-repeat padding-box !important;
		// background: $color-azul !important;
		border-radius: 10px !important;
		// font-family: 'Montserrat Regular' !important;
		// text-transform: uppercase;
	}

	& .MuiInputLabel-standard {
		font-size: 16px;
		// font-family: 'Montserrat Regular' !important;
	}

	& .MuiFormLabel-root {
		color: var(--text-color-inverted) !important;
	}

	& .MuiInputBase-root {
		margin-bottom: 10px;
		border-radius: 20px !important;
	}

	& .MuiFilledInput-underline {
		&::before,
		&::after {
			border: none !important;
		}
	}

	& .MuiOutlinedInput-notchedOutline {
		border: #5a5858 !important;
	}

	& .MuiFormLabel-root.Mui-focused {
		font-weight: normal;
		font-size: 14px;
	}

	& #satisfaccionPerdidas {
		// margin-top: 40px;
	}
}
