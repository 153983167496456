#reporteConsultor {
	& .recharts-yAxis .recharts-text {
		transform: translate(0, 0px);
		fill: var(--side-text-color) !important;
		font-size: 12px;
	}
	& .recharts-xAxis .recharts-text {
		transform: translate(0, 0px);
		fill: var(--side-text-color) !important;
		font-size: 12px;
	}

	& .btn {
		width: 180px !important;
		margin: auto;
	}

	& .label {
		margin-bottom: 5px;
	}

	& .cursor {
		cursor: pointer;
	}
}
