.input_icons {
	width: 100%;
	margin-bottom: 10px;
	margin-left: 0px;
	margin-right: 30px;

    & i {
		position: absolute;
		color: #767676;
	}

    & .icon {
		padding: 10px;
		min-width: 40px;
	}

    & input {
		width: 100%;
		padding: 10px 30px;
	}
}
