#consultores {
    padding: 0 0 25px 0;

    & thead {
        & tr {

            background-color: red;
            color: #fff;
        }
    }

    & th {
        text-align: center;
    }

    & td {
        text-align: center;
    }
}