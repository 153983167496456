#Breadcrumb {
	background-color: transparent;
	color: var(--text-color-inverted);
	width: fit-content;
	padding: 7px 15px 7px 10px;
	border-radius: 0px 5px 20px 0px;
	// margin-bottom: 30px;
	margin-top: 20px;
	// position: fixed;
	z-index: 9;
}

.breadcrumbFondo {
	width: 0px;
	height: 70px;
}

// celular
@media screen and (max-width: 425px) and (orientation: portrait) {
}

@media screen and (max-width: 880px) and (orientation: landscape) {
}
