.header-free {
	background-color: #e10613;
	padding: 15px;
	margin-bottom: 40px;
	position: sticky;
	width: 100%;
	top: 0%;
	z-index: 9;
}

.header-free img {
	width: 150px;
}

.titulo-encuesta {
	font-size: 22px;
	font-weight: bold;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	color: #747373;
}

.MuiFormControlLabel-labelPlacementBottom {
	margin-left: 12px !important;
}

.MuiTypography-root {
	font-size: 16px !important;
	white-space: initial !important;
	color: #747373 !important;
}
